import './Home.css';

function Home(props) {
  return (
    <div className="flex w-full bg-black  justify-center">

      <div className='flex-col overflow-auto relative flex md:w-2/3 bg-slate-500'>
        <img
          loading="lazy"
          srcSet="/assets/fundos.jpg"
          className="absolute h-full"
        />

        <div className="invisible md:visible relative justify-center items-center bg-white bg-opacity-20 flex w-full flex-col px-16 py-3 max-md:max-w-full max-md:px-5 ">
          <div className="flex w-[463px] max-w-full justify-between gap-5 items-start max-md:flex-wrap">
            <div className="font-sans text-black text-sm self-stretch">Quem Somos</div>
            <div className="font-sans text-black text-sm"><a href='#Promovemos'>O Que Promovemos</a></div>
            <div className="font-sans text-black text-sm"><a href='#Onde' >Onde Estamos</a></div>
          </div>
        </div>
        <div className="relative flex w-full flex-col mt-48 px-20 max-md:max-w-full max-md:mt-10 max-md:px-5">
          <img
            loading="lazy"
            srcSet="/assets/logo.png"
            className="
            aspect-[4.27] 
            w-[423px] 
            object-contain 
            object-center 
            overflow-hidden 
            max-w-full 
            ml-0 
            self-start"
          />
          <div className="bg-zinc-300 flex w-[200px] shrink-0 h-2 flex-col ml-3 mt-5 self-start max-md:ml-2.5" />
          <div className="font-sans font-medium text-white text-2xl whitespace-nowrap ml-3 mt-5 self-start max-md:ml-2.5">
            Sistemas de Informação
          </div>
          <div className="font-sans font-semibold text-black text-2xl whitespace-nowrap  mt-60 self-start max-md:mt-10">
            Quem Somos
          </div>
          <div className="font-sans text-black text-justify text-2xl leading-8 self-stretch mt-4 ">
            Somos um grupo de investidores apaixonados por tecnologia que acredita na promessa de disrupturas tecnológicas, juntamente com a possibilidade de criar produtos ou serviços que possam impactar positivamente a sociedade.
          </div>
        </div>
        <div className="relative bg-white bg-opacity-0 w-full pl-4 pr-16 pt-12 pb-0 max-md:max-w-full max-md:pr-5">
          <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
            <div className="flex flex-col items-stretch w-[44%] max-md:w-full max-md:ml-0" id='Promovemos'>
              <img
                loading="lazy"
                srcSet="/assets/placa.jpg"
                className=" 
                object-contain 
                object-center 
                w-full 
                drop-shadow-xl 
                border border-black 
                overflow-hidden 
                mt-5 
                max-md:mt-10"
              />
            </div>
            <div className="flex flex-col items-stretch w-[56%] ml-5 max-md:w-full max-md:ml-0">
              <div className="relative flex flex-col items-stretch my-auto max-md:max-w-full max-md:mt-10">
                <div className="font-sans font-semibold text-black text-2xl max-md:max-w-full" >
                  O Que Promovemos
                </div>
                <div className="font-sans text-black text-justify text-2xl leading-8 mt-4 max-md:max-w-full bg-opacity-35 md:bg-transparent bg-slate-50 md:p-0 p-4">
                  Soluções tecnológicas práticas, funcionais e acessíveis para
                  situações diárias que impactam nas atividades de seus clientes,
                  com o objetivo de tornar as atividades mais céleres e
                  assertivas.
                </div>
              </div>
            </div>
          </div>
          <img
            loading="lazy"
            src="/assets/tecnologias.svg"
            className="aspect-[1.92] object-contain object-center w-full overflow-hidden max-md:max-w-full"
          />

        </div>
        <div className="relative bg-stone-500 flex w-full flex-col pb-4 max-md:max-w-full">
          <div className="bg-zinc-300 bg-opacity-50 flex min-h-[32px] w-full " />
          <div className="self-center
           flex 
           w-full 
           items-stretch 
           justify-between 
           gap-5 
           mt-5 
           max-md:max-w-full 
           max-md:flex-wrap">
            <div className="flex flex-row items-stretch md:pl-16 pl-2">
              <div className='flex flex-col'>
                <div className="font-sans font-semibold text-black text-2xl" id='Onde'>Onde Estamos</div>
                <div className="bg-zinc-300 flex w-[200px] shrink-0 h-2 flex-col ml-0 mt-5 self-start max-md:ml-0" />
                <div className="font-sans text-black text-xl mt-5">Av. Borge de Medeiros, 308, Cj. 64, Centro, Porto Alegre</div>
                <div className="font-sans text-black text-xl mt-5">CEP 90.020-020 - RS - Brasil </div>
                <div className="font-sans text-black text-xl mt-3">WhatsApp (51) 989745275 </div>
                <div className="font-sans text-black text-xl mt-3">contatoimediato@gate51.com.br</div>
                <div className="font-sans text-black text-xl mt-3">CNPJ 34.127.298/0001-17</div>
                {/* <div className="font-sans text-black text-xl whitespace-nowrap mt-4">contatoimediato@gate51.com.br</div> */}
              </div>
            </div>
            <div className='flex pr-8'>
              <img
                loading="lazy"
                srcSet="/assets/icone.png"
                className="invisible md:visible aspect-[1] object-contain object-center w-[48px] overflow-hidden shrink-0 max-w-full mt-20  max-md:mt-10"
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
